@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Source+Sans+3:wght@200;300;400;500;600;700&display=swap");

::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  height: 50px; /* Set the height of the scrollbar thumb */
  background-color: #1E1E1E;
  border-radius: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Outfit", "Source Sans 3", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Outfit", "Source Sans 3", sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
h1,
h2,
h3,
h4,
h5 {
  font-family: "Outfit", sans-serif;
}
p,button,input,a {
  font-family: "Source Sans 3", sans-serif;
}
.Gradient {
  background: linear-gradient(
    278deg,
    rgba(254, 127, 205, 0.44) 7.39%,
    rgba(30, 30, 30, 0.09) 94.32%
  );
  border-radius: 8px;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-animation {
  animation: spin 1.5s linear infinite; /* Apply animation to the image */
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Start rotation */
  }
  100% {
    transform: rotate(360deg); /* End rotation */
  }
}
